import React from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';

import { useIsSpecificLanguage } from '@shared/hooks/useIsSpecificLanguage';

export interface CustomerProfileApplicationHeaderProps {
  date: string;

  state: string;

  stateColor: string;
}

export const CustomerProfileApplicationHeader: React.FC<CustomerProfileApplicationHeaderProps> = ({
  date,
  state,
  stateColor,
}) => {
  const isUkLanguage = useIsSpecificLanguage('uk');

  return (
    <div className="flex space-x-6 items-center">
      <span className="text-black text-base">
        {format(new Date(date), 'dd.MM.yyyy')} {isUkLanguage ? 'р.' : ''}
      </span>

      <div className={clsx('inline-flex justify-center py-2 px-3 rounded-2xl text-xxs', stateColor)}>
        <span>{state}</span>
      </div>
    </div>
  );
};
