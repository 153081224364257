import React from 'react';
import { useTranslation } from 'react-i18next';

import { ShowReceiptButton } from '@widgets/cabinet/applicationCard/ShowReceiptButton';
import { Application } from '@widgets/cabinet/applicationCard/types';

import { CustomerProfileApplicationHeader } from './customer-profile-application-header';
import { CustomerProfileApplicationItems } from './customer-profile-application-items';

export interface CustomerProfileCompletedApplicationProps {
  application: Application;
}

export const CustomerProfileCompletedApplication: React.FC<CustomerProfileCompletedApplicationProps> = ({
  application,
}) => {
  const { items, purchase, updatedAt } = application;

  const { t } = useTranslation();

  return (
    <div className="px-6 py-5 lg:px-8 lg:py-7 bg-white border-2 border-hawkes-blue rounded-lg">
      <div className="flex items-center mb-5">
        <CustomerProfileApplicationHeader
          date={updatedAt}
          state={t('single-completed')}
          stateColor="bg-dew text-shamrock"
        />
      </div>

      <div>
        <p className="mb-3 font-normal text-black text-xxs opacity-50">{t('all-needs-2')}</p>

        <CustomerProfileApplicationItems items={items} />
      </div>

      {purchase?.id && (
        <div className="flex justify-center mt-5 md:mt-0 md:justify-end">
          <ShowReceiptButton purchase={purchase} />
        </div>
      )}
    </div>
  );
};
