import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ApplicationState } from '@shared/enums';
import { selectFilters } from '@store/slices/customer-profile/selectors';

export interface CustomerProfileFilters {
  state?: ApplicationState;
}

export const useCustomerProfileFilters = () => {
  const filters = useSelector(selectFilters);

  return useMemo(() => {
    const result: CustomerProfileFilters = {};
    if (filters.state) {
      result.state = filters.state;
    }

    return result;
  }, [filters]);
};
