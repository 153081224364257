import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';

import { ModalBasic } from '@shared/ui/modal/ModalBasic';
import { Button } from '@shared/ui/buttons/Button';
import {
  getPrettyCategoryName,
  groupApplicationItemsByCategory,
  groupApplicationItemsByIntegration,
} from '@shared/utils/applications';

import { CustomerApplicationHelp } from '@widgets/customer/types';
import { SimpleApplication, ApplicationItem, Application } from '@widgets/cabinet/applicationCard/types';

import { ExternalIntegration, useIntegrationConfigs } from '@hooks/useIntegrationConfigs';
import { useUser } from '@shared/hooks/useUser';
import { useContactRequestData } from '@widgets/profile/useContactRequestData';
import { useSearchParams } from '@shared/hooks/useSearchParams';
import { ContactRequestStatus } from '@store/api/contact-request.api';

interface ApplicationCustomerProfileHelpModalProps {
  isVisible: boolean;
  application: Application;
  onSuccess: (category: CustomerApplicationHelp | null) => void;
  onClose: () => void;
}

const GuestWelcomeText = () => {
  return (
    <div className="text-xs">
      <p className="mb-2">Щоб мати можливiсть допомогти - пройдіть швидку реєстрацію на платформі єДопомога.</p>

      <p className="mb-2">Переваги реєстрації:</p>
      <ol className="list-decimal ml-4">
        <li>Ви отримаєте чек покупки з магазину або аптеки.</li>
        <li>
          Якщо людина не використає свою Передплату вчасно, ви зможете передати її іншій родині, обравши іншу заявку.
        </li>
        <li>Ви завжди зможете побачити статус заявок, по яким ви допомогли.</li>
      </ol>
    </div>
  );
};

const useComponentState = ({
  isVisible,
  application,
  onClose,
  onSuccess,
}: ApplicationCustomerProfileHelpModalProps) => {
  const [user] = useUser();
  const integrations = useIntegrationConfigs();
  const { mySendContactRequests } = useContactRequestData();
  const userId = application.customer.id;

  const usersThatContactRequestIsShared =
    mySendContactRequests?.items
      .filter((cr) => cr.status === ContactRequestStatus.Approved)
      .map((cr) => cr.userInNeed.id) || [];

  const canUserHelpOffline = usersThatContactRequestIsShared.includes(userId);

  const [selectedCategory, setSelectedCategory] = React.useState<CustomerApplicationHelp | null>(null);

  const onlineCategories = React.useMemo(
    () => groupApplicationItemsByIntegration(application, integrations),
    [application, integrations],
  );
  const offlineCategories = React.useMemo(
    () => groupApplicationItemsByCategory(application.items),
    [application.items],
  );

  const onCategoryClick = ({ integration, items }: { integration?: ExternalIntegration; items: ApplicationItem[] }) => {
    setSelectedCategory({ integration, items });
  };

  /**
   * If there are no any available online help and user is a guest
   * then we show welcome text to it
   */
  const isWelcomeGuestTextVisible = onlineCategories.length === 0 && !user?.isMember;

  const onModalApprove = () => {
    if (isWelcomeGuestTextVisible) {
      onSuccess(null);

      return;
    }

    if (!selectedCategory) {
      return;
    }

    onSuccess(selectedCategory);
  };

  return {
    application,
    user,
    canUserHelpOffline,

    selectedCategory,
    onlineCategories,
    offlineCategories,
    onCategoryClick,

    isWelcomeGuestTextVisible,

    isModalVisible: isVisible,
    onModalClose: onClose,
    onModalApprove,
    isApproveBtnDisabled: isWelcomeGuestTextVisible ? false : !selectedCategory,
  };
};

const getCategoryButtonClassName = (selectedCategory: CustomerApplicationHelp | null, items: ApplicationItem[]) =>
  clsx('text-xs', selectedCategory?.items === items && '!text-white !bg-rich-blue');

export const ApplicationCustomerProfileHelpModal: React.FC<ApplicationCustomerProfileHelpModalProps> = (props) => {
  const { t } = useTranslation();
  const iconOptimized = useGetIconOptimized('circle-man-with-laptop');

  const {
    application,
    user,
    isWelcomeGuestTextVisible,
    canUserHelpOffline,

    isModalVisible,
    onModalClose,
    onModalApprove,
    isApproveBtnDisabled,

    selectedCategory,
    onlineCategories,
    offlineCategories,
    onCategoryClick,
  } = useComponentState(props);

  return (
    <ModalBasic
      isVisible={isModalVisible}
      title="choose-how-to-help"
      secondaryBtnLabel="cancel"
      approveBtnLabel={isWelcomeGuestTextVisible ? 'join-edopomoga-team' : 'confirm'}
      imageHeading={{ optimized: iconOptimized }}
      onClose={onModalClose}
      onApprove={onModalApprove}
      isApproveBtnDisabled={isApproveBtnDisabled}
    >
      {isWelcomeGuestTextVisible && <GuestWelcomeText />}

      {onlineCategories.length > 0 && !isWelcomeGuestTextVisible && (
        <div className="mb-9 text-xs">
          <span className="block text-black text-base mb-5">{t('online')}</span>

          <div className="flex flex-col space-y-5">
            {onlineCategories.map(({ integration, items }) => (
              <Button
                key={integration.name}
                as="button"
                variant="chip"
                size="large"
                className={getCategoryButtonClassName(selectedCategory, items)}
                onClick={() => onCategoryClick({ integration, items })}
              >
                {integration.emoji} {t(integration.name)} - {items.length}
              </Button>
            ))}
          </div>
        </div>
      )}

      {canUserHelpOffline && offlineCategories.length > 0 && user?.isMember && (
        <div className="text-xs">
          <span className="block text-black text-base mb-5">{t('from-hand-to-hand')}</span>

          <div className="flex flex-col space-y-5">
            {offlineCategories.map(({ category, items }) => (
              <Button
                key={category}
                as="button"
                variant="chip"
                size="large"
                className={getCategoryButtonClassName(selectedCategory, items)}
                onClick={() => onCategoryClick({ items })}
              >
                {getPrettyCategoryName(category)} - {items.length}
              </Button>
            ))}

            {offlineCategories.length > 1 && (
              <Button
                as="button"
                variant="chip"
                size="large"
                className={getCategoryButtonClassName(selectedCategory, application.items)}
                onClick={() => onCategoryClick({ items: application.items })}
              >
                {t('all-app')}
              </Button>
            )}
          </div>
        </div>
      )}
    </ModalBasic>
  );
};
