import { RootState } from '@store/index';

const getCustomerProfileState = (state: RootState) => state.customerProfile;

/**
 * Selectors
 */

export const selectFilters = (state: RootState) => getCustomerProfileState(state).filters;

export const selectPagination = (state: RootState) => getCustomerProfileState(state).pagination;
