import React from 'react';
import { useTranslation } from 'react-i18next';

import { CustomerProfileFeedbacks } from './customer-profile-feedbacks';
import { CustomerProfileAddress } from './customer-profile-address';
import { CustomerProfileGroups } from './customer-profile-groups';
import { CustomerProfileDescription } from './customer-profile-description';
import { CustomerProfileAvatar } from './customer-profile-avatar';
import { CustomerProfileOauth } from './customer-profile-oauth';

import { Element } from '@shared/interfaces/element.interface';

import { CustomerProfile } from '../types';
// import { ContactRequestButton } from './contact-request-button';

interface CustomerProfileInfoProps extends Element {
  customer: CustomerProfile;
}

export const CustomerProfileInfo: React.FC<CustomerProfileInfoProps> = ({ customer, className }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className="relative px-4 py-4 pt-16 xl:px-8 xl:pb-8 bg-white border-2 border-hawkes-blue rounded-lg">
        <CustomerProfileAvatar
          avatar={customer.avatar}
          className="absolute top-0 left-1/2 -translate-x-2/4 -translate-y-2/4"
        />

        {customer.oauth.length > 0 && (
          <div className="mb-5">
            <p className="mb-3 font-normal text-black text-xxs opacity-50">
              {t('igu-verified').replace('id.gov.ua', '')}
            </p>

            <CustomerProfileOauth oauth={customer.oauth} />
          </div>
        )}

        <div className="mb-5">
          <p className="mb-3 font-normal text-black text-xxs opacity-50">{t('fd-and-comp')}</p>

          <CustomerProfileFeedbacks feedbacks={customer.feedbacks} />
        </div>

        <div className="mb-5">
          <p className="mb-3 font-normal text-black text-xxs opacity-50">{t('locality')}</p>

          <CustomerProfileAddress address={customer.address} />
        </div>

        <div className="mb-5">
          <p className="mb-3 font-normal text-black text-xxs opacity-50">{t('about-customer')}</p>

          <CustomerProfileDescription description={customer.description} interDescription={customer.interDescription} />
        </div>

        {customer.groups.length > 0 && (
          <div className="mb-5">
            <p className="mb-3 font-normal text-black text-xxs opacity-50">{t('applicant-categories')}</p>

            <CustomerProfileGroups groups={customer.groups} />
          </div>
        )}
        {/* Removed from customer profile */}
        {/* <ContactRequestButton customerId={Number(customer.id)} /> */}
      </div>
    </div>
  );
};
