import React from 'react';
import { useTranslation } from 'react-i18next';

import { useIsCollapsed } from '@hooks/useIsCollapsed';

import { Application } from '@widgets/cabinet/applicationCard/types';

import { CustomerProfileApplicationHeader } from './customer-profile-application-header';
import { CustomerProfileApplicationItems } from './customer-profile-application-items';

import { ApplicationCardCollapseController } from '@widgets/cabinet/applicationCard/ApplicationCardCollapseController';
import { ApplicationCardNeedsHolder } from '@widgets/cabinet/applicationCard/ApplicationCardNeedsHolder';
import { ActiveOnlineApplicationCardItems } from '@widgets/cabinet/applicationCard/ActiveOnlineApplicationCardItems';

export interface CustomerProfileInProgressApplicationProps {
  application: Application;
}

export const CustomerProfileInProgressApplication: React.FC<CustomerProfileInProgressApplicationProps> = ({
  application,
}) => {
  const { view, items, updatedAt } = application;

  const { t } = useTranslation();
  const { isCollapsed, toggleCollapsedHandler } = useIsCollapsed();

  return (
    <div className="px-6 py-5 lg:px-8 lg:py-7 bg-white border-2 border-hawkes-blue rounded-lg">
      <div className="flex items-center mb-5">
        <CustomerProfileApplicationHeader
          date={updatedAt}
          state={t('single-in-progress')}
          stateColor="bg-gainsboro text-rich-blue"
        />

        <ApplicationCardCollapseController
          className="hidden md:flex md:ml-auto"
          onClick={toggleCollapsedHandler}
          isCollapsed={isCollapsed}
          view={view}
        />
      </div>

      <div>
        <p className="mb-3 font-normal text-black text-xxs opacity-50">{t('all-needs-2')}</p>

        <CustomerProfileApplicationItems items={items} />
      </div>

      <ApplicationCardCollapseController
        className="flex justify-center mt-5 md:hidden"
        onClick={toggleCollapsedHandler}
        isCollapsed={isCollapsed}
        view={view}
      />

      <ApplicationCardNeedsHolder isCollapsed={isCollapsed} className="mt-0 md:mt-8">
        <ActiveOnlineApplicationCardItems items={items} view={view} />
      </ApplicationCardNeedsHolder>
    </div>
  );
};
