import { useCallback } from 'react';

import { ApplicationState } from '@shared/enums';

import { useAppDispatch } from '@store/hooks';
import { customerProfileActions } from '@store/slices/customer-profile/slice';

export const useUpdateCustomerProfileFilters = () => {
  const dispatch = useAppDispatch();

  return useCallback((state: ApplicationState | null) => {
    dispatch(customerProfileActions.setStateFilter(state));
    dispatch(customerProfileActions.setPaginationPage({ page: 1 }));
  }, []);
};
