import { useCallback } from 'react';

import { useAppDispatch } from '@store/hooks';
import { customerProfileActions } from '@store/slices/customer-profile/slice';

export const useUpdateCustomerProfilePage = () => {
  const dispatch = useAppDispatch();

  return useCallback((page: number) => {
    dispatch(customerProfileActions.setPaginationPage({ page }));
  }, []);
};
