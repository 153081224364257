import React from 'react';
import { useTranslation } from 'react-i18next';

import { OauthProvider } from '@shared/enums';
import { Element } from '@shared/interfaces/element.interface';

import idGovUaLightIcon from '@assets/icons/id-gov-ua-dark.svg';

interface CustomerProfileOauthProps extends Element {
  oauth: string[];
}

const useComponentState = ({ oauth, className }: CustomerProfileOauthProps) => {
  return {
    className,
    isIdGovUaVerified: oauth.includes(OauthProvider.IdGovUa),
  };
};

export const CustomerProfileOauth: React.FC<CustomerProfileOauthProps> = (props) => {
  const { className, isIdGovUaVerified } = useComponentState(props);
  const { t } = useTranslation();

  if (!isIdGovUaVerified) {
    return null;
  }

  return (
    <div className={className}>
      {isIdGovUaVerified && (
        <img
          src={idGovUaLightIcon}
          width="112"
          height="30"
          className="object-contain select-none"
          title={t('id-gov-ua-verified')}
        />
      )}
    </div>
  );
};
