import React, { useEffect } from 'react';

import { CustomerProfileApplicationSkeleton } from './applications/customer-profile-application-skeleton';
import { CustomerProfileApplication } from './applications/customer-profile-application';
import { useCustomerProfileApplications } from './hooks/useCustomerProfileApplications';

import { CustomerProfileApplicationsDropdown } from './customer-profile-applications-dropdown';
import { EmptyApplicationCard } from '@widgets/cabinet/applicationCard/EmptyApplicationCard';

import { Element } from '@shared/interfaces/element.interface';

import { CustomerProfile } from '../types';
import { useCustomerProfileFilters } from './hooks/useCustomerProfileFilters';
import { useCustomerProfilePagination } from './hooks/useCustomerProfilePagination';
import { CustomerProfilePagination } from './customer-profile-pagination';

interface CustomerProfileApplicationsProps extends Element {
  customer: CustomerProfile;
}

export const CustomerProfileApplications: React.FC<CustomerProfileApplicationsProps> = ({ customer, className }) => {
  const [{ applications, totalPages }, { fetchApplications, isLoading }] = useCustomerProfileApplications(customer);

  const filters = useCustomerProfileFilters();
  const pagination = useCustomerProfilePagination();

  useEffect(() => {
    fetchApplications(customer.id, filters, pagination);
  }, [fetchApplications, customer.id, filters, pagination]);

  return (
    <div className={className}>
      <CustomerProfileApplicationsDropdown className="self-start mb-3" />

      <div className="flex flex-col space-y-4">
        {!isLoading &&
          applications.map((application) => (
            <CustomerProfileApplication
              key={`${application.id}-${application.purchase?.id}-${application.state}-${application.updatedAt}`}
              application={application}
            />
          ))}

        {!isLoading && applications.length <= 0 && <EmptyApplicationCard text="Заявник ще не створив жодної заявки" />}

        {isLoading && Array.from({ length: 10 }, (_, idx) => <CustomerProfileApplicationSkeleton key={idx} />)}
      </div>

      <CustomerProfilePagination pageCount={totalPages} />
    </div>
  );
};
