import React, { useMemo } from 'react';
import clsx from 'clsx';

import { ApplicationItem } from '@widgets/cabinet/applicationCard/types';

import { getPrettyCategoryName, groupApplicationItemsByCategory } from '@shared/utils/applications';
import { Element } from '@shared/interfaces/element.interface';

interface CustomerProfileApplicationItemsProps extends Element {
  items: ApplicationItem[];
}

const useComponentState = ({ items, className }: CustomerProfileApplicationItemsProps) => {
  const groupedItems = useMemo(() => groupApplicationItemsByCategory(items), [items]);

  return {
    groupedItems,

    className: clsx(
      'flex flex-col space-y-2 md:space-y-0 md:inline-grid md:grid-cols-3 md:gap-y-2 md:gap-x-5 text-black text-xs',
      className,
    ),
  };
};

export const CustomerProfileApplicationItems: React.FC<CustomerProfileApplicationItemsProps> = (props) => {
  const { groupedItems, className } = useComponentState(props);

  return (
    <div className={className}>
      {groupedItems.map(({ category, items }, idx) => (
        <span className="whitespace-nowrap" key={category}>
          {getPrettyCategoryName(category)} - {items.length}
          {idx !== groupedItems.length - 1 ? ';' : '.'}
        </span>
      ))}
    </div>
  );
};
