import { useCallback, useMemo, useState } from 'react';

import { useLazyGetCustomerApplicationsQuery } from '@store/api/applications.api';

import { CustomerProfile } from '@widgets/customer/types';
import { Application, Customer, SimpleApplication } from '@widgets/cabinet/applicationCard/types';

import { CustomerProfilePagination } from './useCustomerProfilePagination';
import { CustomerProfileFilters } from './useCustomerProfileFilters';

interface GetCustomerProfileApplicationsResult {
  items: SimpleApplication[];

  totalPages: number;
}

export const useCustomerProfileApplications = (customer: CustomerProfile) => {
  const [getCustomerApplications, { isLoading, isFetching }] = useLazyGetCustomerApplicationsQuery();

  const [result, setResult] = useState<GetCustomerProfileApplicationsResult>({
    items: [],
    totalPages: 0,
  });

  const fetchApplications = useCallback(
    async (customerId: string, filters: CustomerProfileFilters, pagination: CustomerProfilePagination) => {
      try {
        const { data } = await getCustomerApplications({
          userId: customerId,
          state: filters.state,
          page: pagination.page,
          perPage: pagination.perPage,
        });
        if (!data) return;

        setResult({ items: data.items || [], totalPages: data.meta.totalPages || 0 });
      } catch (err) {
        // TODO: add handling
      }
    },
    [],
  );

  const applications: Application[] = useMemo(() => {
    return result.items.map((application) => ({
      ...application,
      /**
       * TODO: sync Customer and CustomerProfile when refactor api
       */
      customer: customer as unknown as Customer,
    }));
  }, [result, customer]);

  return [
    { applications, totalPages: result.totalPages },
    { fetchApplications, isLoading: isLoading || isFetching },
  ] as const;
};
