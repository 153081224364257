import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Chip } from '@shared/ui/chips/Chip';
import { Element } from '@shared/interfaces/element.interface';
import { useGetIconOptimized } from '@shared/hooks/useGetIconOptimized';

import { CustomerFeedbacks } from '../types';

interface CustomerProfileFeedbacksProfile extends Element {
  feedbacks: CustomerFeedbacks;
}

const useComponentState = ({ className, feedbacks }: CustomerProfileFeedbacksProfile) => {
  const heartIcon = useGetIconOptimized('ukraine-heart');
  const faceIcon = useGetIconOptimized('frowning-face');

  return {
    className: clsx('flex flex-row flex-wrap gap-2', className),
    chipClassName: 'px-3 whitespace-nowrap select-none',

    heartIcon,
    faceIcon,
    feedbacks,
  };
};

export const CustomerProfileFeedbacks: React.FC<CustomerProfileFeedbacksProfile> = (props) => {
  const { t } = useTranslation();
  const { className, chipClassName, feedbacks, faceIcon, heartIcon } = useComponentState(props);

  const { totalGood, totalBad } = feedbacks;

  return (
    <div className={className}>
      <Chip
        className={chipClassName}
        icon={<GatsbyImage image={getImage(faceIcon)!} alt="Frowning face" className="shrink-0 w-[18px] h-[18px]" />}
        text={`${t('has-complaints')} (${totalBad})`}
      />

      <Chip
        className={chipClassName}
        icon={<GatsbyImage image={getImage(heartIcon)!} alt="Ukraine's heart" className="shrink-0 w-[18px] h-[18px]" />}
        text={`${t('has-feedbacks')} (${totalGood})`}
      />
    </div>
  );
};
