import React from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';

import { CustomerProfileInfo } from './customer-profile-info';
import { CustomerProfileApplications } from './customer-profile-applications';

import { useGetCustomerProfileQuery } from '@store/api/user.api';

import { LoaderWrapper } from '@shared/ui/loader/LoaderWrapper';
import { Loader } from '@shared/ui/loader/Loader';
import { BackButton } from '@shared/ui/buttons/BackButton';

interface CustomerProfileProps {
  id: string;
}

export const CustomerProfile: React.FC<CustomerProfileProps> = ({ id }) => {
  const { t } = useTranslation();
  const { data: customer, isFetching, isLoading } = useGetCustomerProfileQuery(id);

  if (isFetching || isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  if (!customer) {
    navigate('/404');

    return null;
  }

  return (
    <div className="container max-w-7xl mx-auto px-4 pt-3 pb-12">
      <BackButton className="mb-3" />

      <h1 className="font-normal text-xl text-black mb-5">{t('customer-profile')}</h1>

      <div className="flex flex-col space-y-6 lg:space-y-0 lg:flex-row lg:space-x-8">
        <CustomerProfileInfo customer={customer} className="lg:w-[388px] lg:shrink-0 mt-[52px]" />

        <CustomerProfileApplications customer={customer} className="flex grow flex-col" />
      </div>
    </div>
  );
};
