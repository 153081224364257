import React from 'react';
import { ApplicationState } from '@shared/enums';

import { Application } from '@widgets/cabinet/applicationCard/types';

import { CustomerProfileNewApplication } from './customer-profile-new-application';
import { CustomerProfileCompletedApplication } from './customer-profile-completed-application';
import { CustomerProfileInProgressApplication } from './customer-profile-in-progress-application';

interface CustomerProfileApplicationProps {
  application: Application;
}

const getComponentByState = (state: ApplicationState) => {
  switch (state) {
    case ApplicationState.New:
      return CustomerProfileNewApplication;

    case ApplicationState.InProgress:
      return CustomerProfileInProgressApplication;

    case ApplicationState.Completed:
      return CustomerProfileCompletedApplication;

    default:
      return null;
  }
};

export const CustomerProfileApplication: React.FC<CustomerProfileApplicationProps> = ({ application }) => {
  const { state } = application;

  const Application = getComponentByState(state);
  if (!Application) {
    return null;
  }

  return <Application application={application} />;
};
