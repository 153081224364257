import { useSelector } from 'react-redux';

import { selectPagination } from '@store/slices/customer-profile/selectors';

export interface CustomerProfilePagination {
  page: number;

  perPage: number;
}

export const useCustomerProfilePagination = () => {
  const pagination: CustomerProfilePagination = useSelector(selectPagination);

  return pagination;
};
