import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Button } from '@shared/ui/buttons/Button';
import { IconArrowLeft } from '@shared/icons/IconArrowLeft';
import { Element } from '@shared/interfaces/element.interface';

import { useNavigate } from '@hooks/useNavigate';

interface BackButtonProps extends Element {
  onClick?: () => void;
}

export const BackButton = (props: BackButtonProps) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onBackButtonClick = () => {
    if (props.onClick) {
      props.onClick();

      return;
    }

    if (typeof history !== 'undefined' && history.length <= 1) {
      navigate('/');

      return;
    }

    navigate(-1);
  };

  return (
    <div>
      <Button
        as="button"
        variant="secondary"
        size="small"
        className={clsx('flex text-sm items-center', props.className)}
        icon={IconArrowLeft}
        iconClassName="w-[12px] h-auto mr-1"
        onClick={onBackButtonClick}
      >
        {t('back')}
      </Button>
    </div>
  );
};
