import React from 'react';

import { Pagination } from '@shared/ui/Pagination';

import { useUpdateCustomerProfilePage } from './hooks/useUpdateCustomerProfilePage';

interface CustomerProfilePaginationProps {
  pageCount: number;
}

export const CustomerProfilePagination: React.FC<CustomerProfilePaginationProps> = ({ pageCount }) => {
  const updateCustomerProfilePage = useUpdateCustomerProfilePage();

  return (
    <Pagination
      initialPage={0}
      onPageChange={(data) => updateCustomerProfilePage(data.selected + 1)}
      pageCount={pageCount}
    />
  );
};
