import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ApplicationState } from '@shared/enums';
import { SecondaryDropdown } from '@shared/dropdowns/secondary';

import { Option } from '@shared/interfaces/option.interface';
import { useUpdateCustomerProfileFilters } from './hooks/useUpdateCustomerProfileFilters';
import { Element } from '@shared/interfaces/element.interface';

type CustomerProfileApplicationState =
  | 'all'
  | ApplicationState.New
  | ApplicationState.InProgress
  | ApplicationState.Completed;

type CustomerProfileApplicationsDropdownProps = Element;

interface DropdownItem {
  value: CustomerProfileApplicationState;

  label: string;
}

export const CustomerProfileApplicationsDropdown: React.FC<CustomerProfileApplicationsDropdownProps> = ({
  className,
}) => {
  const { t } = useTranslation();

  const dropdownItems = useMemo(
    () =>
      [
        { value: 'all', label: t('all-apps') },
        { value: 'new', label: t('new-apps') },
        { value: 'in_progress', label: t('in-progress-apps') },
        { value: 'completed', label: t('completed') },
      ] as DropdownItem[],
    [t],
  );

  const [selectedItem, setSelectedItem] = useState<DropdownItem>(dropdownItems[0]);
  const updateCustomerProfileFilters = useUpdateCustomerProfileFilters();

  const onDropdownItemChange = (item: Option | null) => {
    const dropdownItem = item as DropdownItem | null;
    if (!dropdownItem) {
      return;
    }

    setSelectedItem(dropdownItem);

    updateCustomerProfileFilters(dropdownItem.value !== 'all' ? dropdownItem.value : null);
  };

  return (
    <div className={className}>
      <SecondaryDropdown
        defaultValue={dropdownItems[0]}
        value={selectedItem.value}
        options={dropdownItems}
        variant="dropdown-menu"
        menuPlacement="bottom"
        onChange={onDropdownItemChange}
      />
    </div>
  );
};
