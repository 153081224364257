import React from 'react';
import { useTranslation } from 'react-i18next';

import { Element } from '@shared/interfaces/element.interface';
import { useIsSpecificLanguage } from '@shared/hooks/useIsSpecificLanguage';
import { FaRegUserCircle } from 'react-icons/fa';

interface CustomerProfileDescriptionProps extends Element {
  description: string | null;

  interDescription?: string | null;
}

const useComponentState = ({ description, interDescription }: CustomerProfileDescriptionProps) => {
  const { t } = useTranslation();

  const isUkLanguage = useIsSpecificLanguage('uk');
  const usedDescription = isUkLanguage ? description : interDescription;

  return {
    className: 'flex space-x-2 md:space-x-4 text-xxs text-black',
    description: usedDescription || t('no-description'),
  };
};

export const CustomerProfileDescription: React.FC<CustomerProfileDescriptionProps> = (props) => {
  const { className, description } = useComponentState(props);

  return (
    <div className={className}>
      <FaRegUserCircle size={24} className="shrink-0" />

      <span>{description}</span>
    </div>
  );
};
