import { useCallback } from 'react';

import { useAppDispatch } from '@store/hooks';
import { customerProfileActions } from '@store/slices/customer-profile/slice';

export const useReloadCustomerProfileFilters = () => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    dispatch(customerProfileActions.reloadFilters());
  }, []);
};
