import React from 'react';

import { CustomerProfile } from '@widgets/customer/profile/customer-profile';

interface CustomerProfilePageProps {
  id: string;
}

const CustomerProfilePage: React.FC<CustomerProfilePageProps> = ({ id }) => {
  return <CustomerProfile id={id} />;
};

export default CustomerProfilePage;
