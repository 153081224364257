import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@shared/ui/buttons/Button';
import { useToggleState } from '@shared/hooks/useToggleState';
import { ApplicationState, Routes } from '@shared/enums';

import { CustomerApplicationHelp } from '@widgets/customer/types';
import { Application } from '@widgets/cabinet/applicationCard/types';

import { ApplicationCustomerProfileHelpModal } from '@widgets/cabinet/volunteer/modals/ApplicationCustomerProfileHelpModal';
import { ApplicationOnlineHelpModal } from '@widgets/cabinet/volunteer/modals/ApplicationOnlineHelpModal';
import { ApplicationConfirmModal } from '@widgets/cabinet/volunteer/modals/ApplicationConfirmModal';
import { ModalGratitudeHandToHand } from '@widgets/cabinet/volunteer/modals/ModalGratitudeHandToHand';
import { ModalGratitudeOnline } from '@widgets/cabinet/volunteer/modals/ModalGratitudeOnline';
import { useActiveTab } from '@widgets/cabinet/customer/hooks/useActiveTab';

import { useAppDispatch } from '@store/hooks';
import { useNavigate } from '@hooks/useNavigate';
import { useUser } from '@shared/hooks/useUser';
import { ExternalIntegration } from '@hooks/useIntegrationConfigs';
import { applicationsActions } from '@store/slices/applications/slice';

import { useReloadCustomerProfileFilters } from '../hooks/useReloadCustomerProfileFilters';

export interface CustomerProfileHelpButtonProps {
  application: Application;
}

function useComponentState({ application }: CustomerProfileHelpButtonProps) {
  const [user] = useUser();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [_, setActiveTab] = useActiveTab();

  const [isHelpModalVisible, toggleIsHelpModalVisible] = useToggleState(false);
  const [isOfflineHelpModalVisible, toggleIsOfflineHelpModalVisible] = useToggleState(false);

  const [isOnlineGratitudeModalVisible, toggleIsOnlineGratitudeModalVisible] = useToggleState(false);
  const [isOfflineGratitudeModalVisible, toggleIsOfflineGratitudeModalVisible] = useToggleState(false);

  const [integration, setIntegration] = useState<ExternalIntegration | null>(null);
  const [helpApplication, setHelpApplication] = useState<Application>(application);

  const reloadCustomerProfileFilters = useReloadCustomerProfileFilters();

  const onHelpModalApprove = useCallback(
    async (result: CustomerApplicationHelp | null) => {
      if (!result) {
        await navigate(Routes.AuthSignUp);

        return;
      }

      const { integration, items } = result;
      const usedApplication = { ...application, items };

      setHelpApplication(usedApplication);

      /**
       * If we have integration then we show modal for a payment
       */
      if (integration) {
        setIntegration(integration);
      } else {
        /**
         * otherwise we show modal for a hand-to-hand help
         */
        dispatch(applicationsActions.removeAll());
        dispatch(applicationsActions.upsertOne(usedApplication));

        toggleIsOfflineHelpModalVisible();
      }

      toggleIsHelpModalVisible();
    },
    [application],
  );

  const onOnlineModalClose = useCallback(() => {
    setIntegration(null);

    setHelpApplication(application);
  }, [application]);

  const onOnlineModalSuccess = useCallback(() => {
    onOnlineModalClose();

    toggleIsOnlineGratitudeModalVisible();
  }, [application]);

  const onOfflineModalClose = useCallback((success?: boolean) => {
    dispatch(applicationsActions.removeAll());
    toggleIsOfflineHelpModalVisible();

    if (success === true) {
      toggleIsOfflineGratitudeModalVisible();
    }
  }, []);

  const onOfflineGratitudeModalApprove = useCallback(async () => {
    toggleIsOfflineGratitudeModalVisible();

    await navigate(Routes.VolunteerCabinet);
    setActiveTab(ApplicationState.InProgress);
  }, []);

  const onOfflineGratitudeModalClose = useCallback(() => {
    toggleIsOfflineGratitudeModalVisible();

    reloadCustomerProfileFilters();
  }, []);

  const onOnlineGratitudeModalApprove = useCallback(async () => {
    toggleIsOnlineGratitudeModalVisible();

    navigate(Routes.VolunteerCabinet);
    setActiveTab(ApplicationState.InProgress);
  }, []);

  const onOnlineGratitudeModalClose = useCallback(() => {
    toggleIsOnlineGratitudeModalVisible();

    reloadCustomerProfileFilters();
  }, []);

  return {
    application,
    user,
    onButtonClick: toggleIsHelpModalVisible,

    helpModalProps: {
      application,
      isVisible: isHelpModalVisible,
      onClose: toggleIsHelpModalVisible,
      onSuccess: onHelpModalApprove,
    },

    onlineHelpModalProps: {
      application: helpApplication!,
      isVisible: integration !== null,
      category: integration?.type!,

      onSuccess: onOnlineModalSuccess,
      onClose: onOnlineModalClose,
    },

    offlineHelpModalProps: {
      isVisible: isOfflineHelpModalVisible,

      onClose: onOfflineModalClose,
    },

    onlineGratitudeModalProps: {
      isVisible: isOnlineGratitudeModalVisible,

      variant: user?.isMember ? 'for-members' : 'for-guests',
      onApprove: onOnlineGratitudeModalApprove,
      onClose: onOnlineGratitudeModalClose,
    },

    offlineGratitudeModalProps: {
      isVisible: isOfflineGratitudeModalVisible,

      onApprove: onOfflineGratitudeModalApprove,
      onClose: onOfflineGratitudeModalClose,
    },
  } as const;
}

export const CustomerProfileHelpButton: React.FC<CustomerProfileHelpButtonProps> = (
  props: CustomerProfileHelpButtonProps,
) => {
  const { t } = useTranslation();

  const {
    application,
    user,

    helpModalProps,
    onButtonClick,

    onlineGratitudeModalProps,
    offlineGratitudeModalProps,
    onlineHelpModalProps,
    offlineHelpModalProps,
  } = useComponentState(props);

  if (user?.id === application.customer.id) {
    return null;
  }

  return (
    <>
      <Button as="button" size="small" variant="rich-blue" onClick={onButtonClick}>
        {t('give-help')}
      </Button>

      <ApplicationCustomerProfileHelpModal {...helpModalProps} />

      <ApplicationOnlineHelpModal {...onlineHelpModalProps} />
      <ApplicationConfirmModal {...offlineHelpModalProps} />

      <ModalGratitudeOnline {...onlineGratitudeModalProps} />
      <ModalGratitudeHandToHand {...offlineGratitudeModalProps} />
    </>
  );
};
