import * as React from 'react';

export const IconArrowLeft = ({ className }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      className={`fill-current ${className}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.125 8.125C12.6773 8.125 13.125 7.67728 13.125 7.125C13.125 6.57272 12.6773 6.125 12.125 6.125L4.28921 6.125L7.83211 2.58211C8.22263 2.19158 8.22263 1.55842 7.83211 1.16789C7.44158 0.777369 6.80842 0.777369 6.41789 1.16789L1.16789 6.41789C0.777368 6.80842 0.777368 7.44158 1.16789 7.83211L6.41789 13.0821C6.80842 13.4726 7.44158 13.4726 7.83211 13.0821C8.22263 12.6916 8.22263 12.0584 7.83211 11.6679L4.28921 8.125L12.125 8.125Z"
      />
    </svg>
  );
};
